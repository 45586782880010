import styled from "styled-components"
import Img from "gatsby-image"

export const Container = styled.div``

export const List = styled.ul`
  margin: 8px 4px;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  list-style: none;
`

export const Item = styled.li`
  flex: 0 0 32px;
  margin: 0 4px;

  img {
    width: 32px;
    height: 32px;
  }
`

export const Button = styled.button`
  padding: 0;
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  width: 100%;
  cursor: pointer;
  outline: none;
  filter: grayscale(${props => (props.active ? 0 : 100)});
  transition: all 0.2s ease;

  &:hover {
    filter: grayscale(0);
  }
`

export const Thumb = styled(Img)`
  height: 100%;
`
