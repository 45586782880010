import styled from "styled-components"
import { Link } from "gatsby-plugin-intl"

export const Container = styled.header`
  margin-top: 24px;

  @media screen and (min-width: 790px) {
    margin-top: 0;
  }

  h1 {
    margin: 0;
    font-weight: normal;
    font-size: 70px;
    white-space: nowrap;

    @media screen and (min-width: 790px) {
      font-size: 23vw;
      line-height: 31.5vw;
    }

    @media screen and (min-width: 1230px) {
      font-size: 288px;
      line-height: 384px;
    }

    span {
      display: block;
      font-size: 50px;

      @media screen and (min-width: 790px) {
        font-size: 14.5vw;
        line-height: 19vw;
        margin-top: -6vw;
      }

      @media screen and (min-width: 1230px) {
        font-size: 178px;
        line-height: 237px;
        margin-top: -72px;
      }
    }
  }
`

export const Anchor = styled(Link)`
  color: var(--color-dark);
  text-decoration: none;
`

export const Description = styled.h2`
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  margin: 24px 0 0;

  @media screen and (min-width: 790px) {
    font-size: 24px;
    line-height: 32px;
    margin: 44px 0 0;
  }
`
