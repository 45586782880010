import React from "react"

import { Container, List } from "./styles"

const data = [
  {
    icon: "icon-github",
    url: "https://github.com/nncl",
    title: "Github",
    target: "_blank",
  },
  {
    icon: "icon-medium",
    url: "https://clmeida.medium.com",
    title: "Medium",
    target: "_blank",
  },
  {
    icon: "icon-linkedin",
    url: "https://www.linkedin.com/in/cau%C3%AA-almeida-4bb47963/",
    title: "Linkedin",
    target: "_blank",
  },
  {
    icon: "icon-envelope",
    url: "mailto:nncl@live.com",
    title: "E-mail",
    target: "_self",
  },
  {
    icon: "icon-npm",
    url: "https://www.npmjs.com/~clmeida",
    title: "npm",
    target: "_blank",
  },
]

const Socials = () => (
  <Container>
    <List>
      {data.map(item => (
        <li key={item.icon}>
          <a href={item.url} title={item.title} target={item.target}>
            <span className={item.icon} />
          </a>
        </li>
      ))}
    </List>
  </Container>
)

export default Socials
