import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { changeLocale, useIntl } from "gatsby-plugin-intl"
import { Container, List, Item, Thumb, Button } from "./styles"

const Top = () => {
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "en.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImagePt: file(relativePath: { eq: "pt.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <List>
        <Item>
          <Button
            onClick={() => changeLocale("pt-BR")}
            active={intl.locale === "pt-BR"}
          >
            <Thumb fluid={data.placeholderImagePt.childImageSharp.fluid} />
          </Button>
        </Item>
        <Item>
          <Button
            onClick={() => changeLocale("en")}
            active={intl.locale !== "pt-BR"}
          >
            <Thumb fluid={data.placeholderImage.childImageSharp.fluid} />
          </Button>
        </Item>
      </List>
    </Container>
  )
}

export default Top
