import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import Section from "~/components/Section"
import Socials from "~/components/Socials"

import { Container, Wrapper, Title, Description } from "./styles"

const Form = () => (
  <Section>
    <Container>
      <Title>
        <FormattedMessage id="footer_title" />
      </Title>
      <Description>
        <FormattedMessage id="footer_description" />
      </Description>

      <Wrapper>
        <Socials />
      </Wrapper>
    </Container>
  </Section>
)

export default Form
