import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  text-align: center;
  margin-top: 90px;

  @media screen and (min-width: 1024px) {
    flex: 1;
    margin-top: 0;
  }
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 30px;
  color: var(--color-smoked-tint);

  a {
    text-decoration: none;
    color: inherit;
    transition: all 0.2s ease;
    margin-bottom: 16px;
    display: inline-block;

    &:hover {
      color: var(--color-dark);
    }
  }

  li {
    display: inline;
    vertical-align: middle;
    padding: 0 6px;

    &:nth-child(4):before {
      display: block;
      content: "";
    }

    &:last-child {
      position: relative;
      top: 3px;
    }
  }
`
