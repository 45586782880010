import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

import { Container } from "./styles"

const Footer = () => {
  return (
    <Container>
      <FormattedMessage id="copyright" />
    </Container>
  )
}

export default Footer
