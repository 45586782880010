import styled from "styled-components"

export const Container = styled.div`
  text-align: center;
`

export const Wrapper = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
`

export const Title = styled.h4`
  font-weight: bold;
  font-size: 38px;
  line-height: 48px;
  margin: 0;

  @media screen and (min-width: 1024px) {
    font-size: 144px;
    line-height: 192px;
  }
`

export const Description = styled.h5`
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  margin: 0 0 60px;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 174px;
  }
`
