import React from "react"
import PropTypes from "prop-types"

import { Container, Anchor, Description } from "./styles"
import Top from "~/components/Top"

import { useIntl, FormattedHTMLMessage } from "gatsby-plugin-intl"

const Header = ({ siteTitle }) => {
  const intl = useIntl()

  return (
    <>
      <Top />
      <Container data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
        <div className="l-container">
          <h1>
            <Anchor
              to="/"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "head_label",
                  defaultMessage: "Fullstack <span>developer</span>",
                }),
              }}
            />
          </h1>

          <Description>
            <FormattedHTMLMessage
              id="head_description_label"
              defaultMessage="Hello, my name is <strong>Cauê</strong> and it’s a pleasure to meet
            you. <br />
            <strong>Welcome</strong> to my website. Here you will find some of
            the things I've done. Hope you enjoy it!"
            />
          </Description>
        </div>
      </Container>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
